@import '../styles/_imports';

.root {
  @include csp-vstack();
  height: 100%;
  .tabs {
    @include csp-hstack();
    gap: var(--space-100);
    margin-bottom: -1px;
    z-index: 0;

    .tab {
      @include csp-hstack($align: center, $justify: center);
      gap: var(--space-200);
      color: var(--col-brand-neutral-900);
      font-weight: 500;
      background-color: var(--col-brand-neutral-100);

      padding: var(--space-300) var(--space-500);

      border: solid 1px var(--col-brand-neutral-300);
      border-bottom: solid 1px var(--col-brand-neutral-900);

      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      cursor: pointer;

      &.active {
        background-color: var(--col-brand-neutral-000);
        border: solid 1px var(--col-brand-neutral-900);
        border-bottom-color: white;

        &.fillIco svg * {
          fill: var(--col-brand-neutral-900);
        }
        &.strokeIco svg * {
          stroke: var(--col-brand-neutral-900);
        }
      }

      &.fillIco svg * {
        fill: var(--col-primary-neutral-080);
      }
      &.strokeIco svg * {
        stroke: var(--col-primary-neutral-080);
      }

      &.lalign {
        justify-content: flex-start;
      }

      .txt {
        display: none;
        @include csp-breakpoint-up(md) {
          display: initial;
        }
      }
      .ico {
        > svg {
          @include csp-fix-size(24px);
        }
      }
    }
  }

  .tabContent {
    display: none;
    background-color: var(--col-brand-neutral-000);
    border: solid 1px var(--col-brand-neutral-900);
    padding: var(--space-400);

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 100%;

    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &.active {
      display: block;
      height: 100%;
    }
  }
}
