@import '../styles/_imports';

.root {
  @include csp-padding($top: xs, $bottom: xs, $left: s, $right: s);
  min-width: 80px;
  border: solid 1px var(--col-primary-500);
  border-radius: 43px;
  transition: 400ms;
  height: fit-content;
  width: fit-content;

  &.block {
    width: 100%;
  }

  svg path {
    fill: currentColor;
    transition: 400ms;
  }

  &.allignRight {
    margin-left: auto;
  }

  &.primary {
    background-color: var(--col-primary-500);
    border: solid 1px var(--col-primary-500);
    color: var(--col-brand-neutral-000);
    @include csp-control-shadow();
    font-weight: 700;
    &:disabled {
      opacity: 0.6;
      // background-color: var(--col-dataviz-blue-medium-persian);
    }
  }

  &.secondary {
    background-color: var(--col-brand-neutral-000);
    color: var(--col-brand-neutral-900);
    border: solid 1px var(--col-brand-neutral-300);
    @include csp-control-shadow();
    font-weight: 700;
    // Colors/Brand/Alt/Brand Secondary-300
  }

  &.icon {
    background-color: transparent;
    border-color: transparent;
    min-width: auto;
    padding: var(--space-100) var(--space-100);
  }

  &.warn {
    background-color: transparent;
    color: csp-color(warn);
    border-color: csp-color(warn);

    &:hover {
      background-color: csp-color(warn);
      color: csp-color(fg-invert);
    }
  }

  &.danger {
    background-color: transparent;
    border-color: var(--col-functional-error-full);
    font-weight: 700;

    &:hover {
      background-color: var(--col-functional-error-full);
      color: var(--col-primary-white);
    }
  }

  &:hover {
    text-decoration: none;
    // svg path{
    //   fill: csp-color(fg-invert);
    // }
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }
}

a.root {
  text-decoration: none;
  text-align: center;
}

.buttonSegments.size_s .root,
.buttonToggles.size_s .root,
.root.size_s {
  font-size: csp-font-size(s);
  padding: var(--space-100) var(--space-300) var(--space-100) var(--space-200);
}
.buttonSegments.size_xs .root,
.buttonToggles.size_xs .root,
.root.size_xs {
  font-size: 12px;
  line-height: 14px;
  padding: var(--space-100) var(--space-200) var(--space-100) var(--space-100);
}
.buttonSegments.size_l .root,
.buttonToggles.size_l .root,
.root.size_l {
  font-size: csp-font-size(l);
  // @include csp-padding($top: xs, $bottom: xs, $left: m, $right: m);
  padding: var(--space-200) var(--space-400) var(--space-200) var(--space-300);
}

.buttonSegments {
  @include csp-control-shadow();
  border-radius: 43px;
  .root {
    border-radius: 0;
    background-color: var(--col-brand-neutral-000);
    color: var(--col-brand-neutral-900);
    border-color: var(--col-brand-neutral-300);
    border-left-style: none;
    border-right-style: none;
    padding-top: var(--space-100);
    padding-bottom: var(--space-100);
  }

  .root:first-child {
    border-top-left-radius: 43px;
    border-bottom-left-radius: 43px;
    border-left-style: solid;
    border-right-style: solid;
  }
  .root:last-child {
    border-top-right-radius: 43px;
    border-bottom-right-radius: 43px;
    border-right-style: solid;
  }

  .root:not(:last-child).root:not(:first-child) {
    border-right-style: solid;
  }
  .root:hover {
    background-color: var(--col-brand-neutral-000);
    color: var(--col-brand-neutral-900);
  }
  .root.toggled {
    background-color: var(--col-primary-500);
    color: var(--col-brand-neutral-000);
  }
}

.buttonToggles {
  border: solid 1px var(--col-brand-neutral-200);
  width: fit-content;
  padding: var(--space-100);
  border-radius: 16px;

  .root {
    border-radius: 43px;
    background-color: var(--col-brand-neutral-000);
    padding-top: var(--space-100);
    padding-bottom: var(--space-100);

    border-color: var(--col-brand-neutral-300);
    @include csp-control-shadow();
    color: var(--col-brand-neutral-900);
    @include csp-hstack($align: center, $justify: left);
    gap: var(--space-100);
    font-weight: 500;

    &:hover {
      background-color: var(--col-brand-neutral-000);
      color: var(--col-brand-neutral-900);
    }

    &.toggled,
    &.toggled:hover {
      background-color: var(--col-primary-neutral-020);
      border-color: var(--col-primary-neutral-020);
      box-shadow: none;
    }
  }
}
