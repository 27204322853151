@import '../../styles/_imports';

.root {
  // background: linear-gradient(180deg, #0096e9 0%, #0352c8 100%);
  // height: ;
  z-index: 100000;
  width: var(--menu-width);
  position: fixed;
  top: 0;
  height: 100vh;
  transition: all 0.3s;

  border-top-right-radius: 40px;

  @include app-main-bg();

  .collapseBtn {
    // transform: scaleX(1);
    transition: all 0.3s;
  }
}

.chooseLangModal {
  width: 780px;
}
.langTile {
  width: 190px;
  svg {
    width: 40px;
    height: 28px;
    border-radius: 4px;
  }
  &.notSel {
    @include csp-common-shadow();
    white-space: nowrap;
  }
  // box-shadow: 0px 6px 8px 0px #B7CBD352;
  // box-shadow: 0px 1px 1px 0px #B7CBD3CC;
}

.isCollapsed {
  width: var(--nav-collapsed-width);
  .collapseBtn {
    transform: scaleX(-1);
  }
  // width: 80px;
  .item {
    gap: 0;
    justify-content: center;
  }

  .item .text {
    grid-template-columns: 0fr;
  }
}

.link {
  text-decoration: none;
  // text-decoration-color: lime;
}
.profileContextMenu {
  > div > div {
    padding-right: var(--space-600);
  }
}
.item {
  // justify-content: center;
  min-width: 64px;
  transition: all 0.3s;

  .text {
    display: grid;
    grid-template-columns: 1fr;
    transition: grid-template-columns 0.3s;
    white-space: nowrap;
    > span {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  &.selected {
    background-color: #002f658f;
    border-radius: 40px;
  }
}
