@import '../../styles/_imports';

.root {
  height: 100%;
  width: 100%;
  padding: 6px;
  border-radius: 50%;

  transition: all 0.6s ease-in;
  transform: rotate(0deg);
  aspect-ratio: 1 / 1;

  &.counterClockWise {
    transform: scale(-1, 1);
  }

  &.animate {
    padding: 0%;
    box-shadow: none;

    .holeOuter .hole {
      padding: 0%;
      box-shadow: none;
    }
  }

  .holeOuter {
    padding: 20px;
    width: 100%;
    height: 100%;
    background-color: var(--col-brand-neutral-000);
    border-radius: 50%;

    .hole {
      width: 100%;
      height: 100%;
      border-radius: 50%;

      // will be reset on nested
      border: solid 1px var(--col-brand-secondary-150);
      padding: 10px;
      box-shadow: 0px 6px 8px 0px color-mix(in srgb, var(--col-brand-neutral-300) 32%, transparent),
        0px 1px 1px 0px color-mix(in srgb, var(--col-brand-neutral-300) 80%, transparent);

      //

      transition: all 0.6s ease-in;

      @include csp-vstack($align: center, $justify: center);

      text-align: center;
    }
  }

  .root .holeOuter .hole {
    border: none;
    padding: 0;
    box-shadow: none;
  }
}
