@import '../../../../styles/_imports';

.root {
  .infoIco {
    padding-left: csp-padding(xs);
    width: min-content;
    cursor: pointer;
    svg {
      @include csp-fix-size(16px);
    }
    svg path {
      fill: csp-color(brand-primary-500, base);
    }
  }

  .filterCard {
    @include csp-hstack();
    gap: csp-margin(m);
    flex-wrap: wrap;

    > div {
      flex: 1;
    }
  }

  .filters {
    @include csp-hstack();
    gap: csp-margin(xs);
    align-items: flex-start;

    &.rateTypeFilters {
      @include csp-breakpoint-up(md) {
        justify-content: flex-end;
        margin-left: auto;
      }
    }
  }

  .row {
    padding: csp-padding(xs);
  }

  .roleTypeCol {
    text-transform: 'uppercase';
  }

  .actions {
    @include csp-hstack();
    gap: csp-margin(s);
    margin-left: auto;
    .btn {
      min-width: 100px;
      @include csp-breakpoint-up(lg) {
        min-width: 140px;
      }
    }
  }
}

.roleClientTitle {
  @include csp-ellipsis();
  font-size: csp-font-size(s);
  margin-top: csp-margin(xs);
}

.deletePopupRow {
  max-width: 420px;
  &.alert {
    color: csp-color(red);
  }
  &.warn {
    color: csp-color(yellow);
  }
}

.submitError {
  padding-left: 2px;
  color: csp-color(red);
  border-color: csp-color(red);
}
