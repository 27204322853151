@import '../../styles/imports';

.headerWrapper {
  position: relative;
  .closeBtn {
    position: absolute;
    right: -16px;
    top: -16px;
  }
}
.root {
  @include csp-common-shadow();
  border: solid 1px var(--col-brand-neutral-300);
  border-radius: 8px;
  padding: var(--space-600) var(--space-600);
  min-width: 460px;
  min-height: 300px;
  background-color: var(--col-primary-neutral-010);

  gap: var(--space-600);

  &:focus-visible {
    outline: none;
  }

  &::backdrop {
    background: var(--col-primary-neutral-100);
    opacity: 0.9;
  }

  @keyframes showTop {
    from {
      transform: translateY(-100vh);
    }
    to {
      transform: translateY(0vh);
    }
  }
  @keyframes hideTop {
    to {
      transform: translateY(-100vh);
    }
  }

  @keyframes showRight {
    from {
      transform: translateX(100vw);
    }
    to {
      transform: translateX(0vw);
    }
  }
  @keyframes hideRight {
    to {
      transform: translateX(100vw);
    }
  }

  &[open] {
    @include csp-vstack($align: center);

    animation: showTop 0.8s ease normal;
    &.appearRight {
      animation: showRight 0.8s ease normal;
    }
  }
  &.closed {
    animation: hideTop 0.8s ease normal;
    &.appearRight {
      animation: hideRight 0.8s ease normal;
    }
  }
}
