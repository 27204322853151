@import '../../../styles/imports';

.root {
  @include csp-padding($top: s, $bottom: s);

  .commonParent {
    > i {
      font-size: csp-font-size(xs);
      font-weight: 300;
    }
  }

  .affiliateSelectBar {
    @include csp-hstack($align: center);
    gap: csp-margin(m);
  }
}

.popup {
  // max-width: 60vw;
  // max-height: unset;

  .body {
    @include csp-vstack($align: center);
    padding-top: csp-padding(m);
    height: 250px;
    overflow: unset;
    padding-bottom: 0px;
    margin-bottom: 0px;

    :global(.singleSelectPrefix__option) {
      padding-top: csp-padding(xxs);
      padding-bottom: csp-padding(xxs);
    }

    h2 {
      font-size: csp-font-size(m);
      margin-bottom: csp-margin(m);
      margin-top: csp-margin(m);
    }

    .select {
      max-width: 320px;
      width: 320px;
      // @include csp-margin($left: auto, $right: auto, $top: xl);
    }

    .affiliateOpt {
      @include csp-vstack();
      max-width: 100%;

      i {
        flex: 1;
        font-style: normal;
        color: csp-color(fg, light);
        font-size: csp-font-size(xs);
      }
      b {
        @include csp-ellipsis();
        flex: 1;
        font-weight: 600;
        font-size: csp-font-size(s);
      }
    }
  }

  .footer {
    padding-top: 0px;
    margin-top: 0px;
    //background-color: yellow;
  }
}
